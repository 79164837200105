import React, { useState } from 'react';
import './Accordion.scss';

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="columns is-centered ">
        <div className="column is-10 black-border-top">
          <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
            <h3>{title}</h3>
            <h3 className={isActive ? 'tilted' : 'tilt'}>+</h3>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-8 is-offset-1">
          {isActive && <p className="accordion-content" dangerouslySetInnerHTML={{ __html: content }} />}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
